import { lazy } from 'react';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

const AccountDelete = Loadable(lazy(() => import('../views/Open Page/AccountDelete/index')));
const VerifyDelete = Loadable(lazy(() => import('../views/Open Page/AccountDelete/CodeVerification3')));


const OpenRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/account-delete',
            element: <AccountDelete />
        },
        {
            path: '/verify-account-delete',
            element: <VerifyDelete />
        },
    ]
};

export default OpenRoutes;
